@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
    height: 100%;
}
body {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    height: 100%;
    margin: 0;
}
#root {
    min-height: 100%;
    .App {
        min-height: 100%;
    }
}


h1 {
    font-family: 'Mulish', sans-serif;
    line-height: 1em;
}
h2 {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
}
p {
    font-size: 16px;
}
a {
    //color: #000;
}
ul {
    list-style: none;
    font-size: 16px;
    line-height: 1.2em;
    padding: 0;
}

fieldset {
    box-sizing: border-box;
    border: none;
    padding: 0;
    margin: 0;

    > p {
        margin: 0 0 1em;
    }
    label {
        display: block;
        margin: 0 0 5px;
    }
    .checkbox,
    .radio {
        > p {
            margin: 1em 0 0;

            &:first-of-type {
                margin: 0;
            }
        }
        label {
            display: inline-block;
        }
    }
    .note {
        margin: 10px 0;
    }

    .phone {
        display: flex;
        flex-wrap: wrap;
        label {
            width: 100%;
        }
        .field {
            display: flex;
            width: 100%;
            .area-code {
                flex-grow: 0;
                width: 50px;
                padding: 0 10px;
                margin: 0 10px 0 0;
            }
            .phone-number {
                flex-grow: 1;
                width: auto;
                padding: 0 8px
            }
        }

    }
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=tel] {
    box-sizing: border-box;
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 50px;
    padding: 0 1em;

    border: 1px solid#e2e2e2;
    border-radius: 5px;
    outline: 0;
}
input[type=radio] {
    margin: 5px 5px 0 0;
}
textarea {
    box-sizing: border-box;
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 100%;
    padding: 1em;
    border: 1px solid#e2e2e2;
    outline: 0;
}

select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;

    cursor: inherit;
    box-sizing: border-box;
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 50px;
    padding: 0 0 0 1em;
    margin: 0;
    border: none;
    outline: none;
}
select::-ms-expand {
    display: none;
}

.select {
    width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;

    //display: grid;
    display: flex;
    align-items: center;
}

.select::after {
    content: "";
    width: 12px;
    height: 8px;
    margin: 8px;
    background-color: #e2e2e2;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);

  }
//   .select--disabled {
//     cursor: not-allowed;
//     background-color: #efefef;
//     background-image: linear-gradient(to top, #ddd, #eee 33%);
//   }
select:disabled {
    background-color: #efefef;
}
select,
.select:after {
    //grid-area: select;
    justify-self: end;
}

.error-msg {
    display: none;
    width: 100%;
    padding: 5px;
    text-align: center;
    color: #fff;
    background-color: #E5183F;
    border-radius: 5px;
}

.error {
    input,
    .select {
        border: 1px solid #E5183F !important;
    }
    
    .error-msg {
        display: block;
    }

    &.checkbox-group,
    &.radio-group {
        padding: 5px;
        border: 1px solid #E5183F !important;
    }
}

.page-logo {
    width: 167px;
    height: 60px;
    margin: 0;
    @media screen and (max-width:710px) {
        width: 120px;
    }
    a {
        display: block;
        width: 100%;
        height: 100%;
        background: url(../img/aq-logo-black.png) no-repeat center center;
        background-size: contain;

        img {
            display: none;

        }
    }
}

.nav-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    //height: 200px;
    margin: 0;

    a {
        display: block;
        width: 140px;
        height: 44px;
        margin: 2em 0;
        background: url(../img/aq-logo-black.png) no-repeat center center;
        background-size: contain;

        img {
            display: none;

        }
    }
}

.nav {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    flex-flow: column;
    flex-grow: 0;
    flex-shrink: 0;
    // flex-wrap: wrap;
    width: 250px;
    padding: 20px;
    background-color: #fff;

    ul {
        width: 100%;
        margin: 0;
        li {
            a {
                display: flex;
                align-items: center;
                font-size: 18px;
                padding: 20px;
                text-decoration: none;
                color: #333;

                img {
                    margin: 0 10px 0 0;
                }
            }
            &.on {
                a {
                    
                    border-radius: 5px;
                    color: #fff;
                    background-color: #000;
                }
            }
        }
    }
    @media screen and (max-width: 640px) {
        position: absolute;
        top: 0;
        left: -250px;
        z-index: 2;
        height: 100%;
        transition: left 0.2s;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 5px;
    }
    &.on {
        left: 0;
    }
}
.content {
    //width: 100%;
    width: calc(100vw - 265px);
    padding: 25px;
    @media screen and (max-width: 710px) {
        width: 100%;
    }
}

.section {
    .content {
        max-width: 1200px;
        margin: 100px auto;
    }

    .top-nav {
        //position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        max-width: 1200px;
        margin: 0 auto;

        @media screen and (max-width:710px) {
            padding: 0 5px;
            //margin: -82px 0 0;
        }
        
        .page-logo {
            a {
                background-image: url(../img//aq-logo-black.png);
                @media screen and (max-width:710px) {
                    width: 120px;
                }
            }
        }
        .mobile-nav-btn {
            display: none;
            a {
                display: block;
                .svg-inline--fa {
                    font-size: 24px;
                    color: #333;
                }
            }
            

            @media screen and (max-width: 710px) {
                display: block;
                margin: 0 10px 0 0;
            }
        }
        .nav {
            font-size: 18px;
            width: 60%;
            min-width: 310px;
            padding: 0;
            background-color: transparent;
            @media screen and (max-width:710px) {
                font-size: 14px;
                min-width: 215px;
                padding: 0;
                background-color: #fff;
            }
            .close-btn {
                display: none;
                padding: 0;
                margin: 0;
                text-align: right;
                @media screen and (max-width:710px) {
                    display: block;
                }
                 a {
                    box-sizing: content-box;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    padding: 20px;
                    color: #333;
                 }
                .svg-inline--fa {
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                }
            }
            ul {
                display: flex;
                justify-content: flex-end;
                flex-flow: row;
                align-items: center;
                @media screen and (max-width:710px) {
                    display: flex;
                    justify-content: center;
                    flex-flow: column;
                    font-size: 14px;
                    min-width: 215px;
                    padding: 20px;
                    background-color: #fff;
                }
                li {
                    margin: 0;
                    @media screen and (max-width:710px) {
                        margin: 0;
                    }
    
                    a {
                        display: block;
                        font-size: 14px;
                        padding: 20px;
                        text-align: center;
                        text-decoration: none;
                        color: #333;
                        @media screen and (max-width:710px) {
                            padding: 20px;
                        }
                    }
                    &.btn.link {
                        a {
                            display: block;
                            font-size: 13px;
                            font-weight: 600;
                            text-transform: uppercase;
                            padding: 10px 20px;
                            border-radius: 5px;
                            color: #fff;
                            background-color: #8bc34a;
                            @media screen and (max-width:710px) {
                                font-size: 11px;
                                padding: 15px;
                                margin: 0;
                            }
                        }
    
                    }
                }
            }

            @media screen and (max-width: 710px) {
                display: none;

                &.on {
                    display: block;
                    //position: absolute;
                    //top: -65px;
                    position: fixed;
                    width: 100%;
                    height: 100vh;
                    margin: 0;
                }
            }
        }
    }

    &.header {
        padding: 20px 0;
        background-color: #efefef;

    }

    .section-header {
        width: 100%;
        margin: 0;
        
        .page-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mobile-nav-btn {
                display: none;
                .svg-inline--fa {
                    font-size: 24px;
                    color: #333;
                }
                margin: 0 10px 0 0;

                @media screen and (max-width: 640px) {
                    display: block;
                }
            }
            
            h1 {
                font-size: 30px;
                flex-grow: 1;
            }
            .user {
                position: relative;
                display: flex;
                align-items: center;
                .avatar { 
                    width: 50px;
                    height: 50px;
                    margin: 0 10px 0 0;
                    padding: 0;
                    overflow: hidden;
                    flex-shrink: 0;
                    border-radius: 50%;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        background-color: #333;
                        img {
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                        }
                        .fa-user {
                            font-size: 30px;
                            color: #fff;
                        }
                    }
                }
                .svg-inline--fa {
                    font-size: 20px;
                }
                .account-nav {
                    display: none;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 140px;
                    height: 0;
                    margin-top: 10px;
                    border-radius: 5px;
                    background-color: #fff;
                    transition: height 0.1s;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
                    z-index: 2;
                    ul {
                        margin: 0;

                        li {
                            a {
                                display: flex;
                                align-items: center;
                                font-size: 16px;
                                line-height: 1.2em;
                                padding: 15px;
                                text-decoration: none;
                                //border-bottom: 1px solid #dbdbdb;
                                color: #000;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin: 0 5px 0 0;
                                }
                            }
                        }
                    }
                    &.on {
                        display: block;
                        height: auto;
                    }
                }
            }
        }
        .intro {
            padding: 0 0 10px;
            border-bottom: 1px solid #d8d8d8;
            h2 {
                font-size: 24px;
                margin: 10px 0;
            }
            p {
                font-size: 18px;
                margin: 10px 0;
            }
        }


    }

    .card-group {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px;
        margin: 1em 0;

    }
    .card {
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        h3 {
            font-size: 24px;
            margin: 0 0 15px;
        }
        .card-header {
            border-bottom: 1px solid #d8d8d8;
            h3 {
                font-size: 24px;
                margin: 0 0 15px;
            }
        }
        .card-content {

        }
    }

    &.login {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        min-height: 100vh;

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0;
            background-color: #efefef;
        }
        .content {
            box-sizing: border-box;
            padding: 0 10px;
            margin: 3em auto auto;
            text-align: center;
            width: 100%;
            max-width: 480px;

            h1 {
                font-size: 16px;
                font-family: "Mulish";
            }
            h2 {
                font-family: "Mulish";
            }

            .btn {
                margin: 1em 0;
                &.link {
                    a {
                        font-size: 14px;
                        padding: 10px;
                        border: 1px solid #333;
                    }

                    &.facebook-login {
                    
                        a {
                            border: none;
                            color: #fff;
                            background-color: #3B5898;
                        }
                    }
                }
                &.green {
                    a {
                        padding: 10px 40px;
                    }
                    input, 
                    button {
                        padding: 10px 40px;
                    }
            
                }
                
            }
            .or {
                text-transform: uppercase;
            }

            form {
                .input {

                    input {
                        font-size: 14px;
                        height: 41px;
                    }

                    // .phone {
                    //     display: flex;

                    //     .area-code {
                    //         flex-grow: 0;
                    //         width: 50px;
                    //         padding: 0 10px;
                    //         margin: 0 10px 0 0;
                    //     }
                    //     .phone-number {
                    //         flex-grow: 1;
                    //         width: auto;
                    //         padding: 0 10px
                    //     }
                    // }
                }
                .submit {
                    p {
                        margin: 0 0 1em;
                    }
                }
            }

            .message{
                margin: 2em 0;
                &.email {
                    padding: 10px;
                    border: 1px solid #333;
                }
            }
        }
        // .footer {
        //     width: 100%;
        //     padding: 20px 0;
        //     color: #fff;
        //     background-color: #333;
        //     .content {
        //         box-sizing: border-box;
        //         display: flex;
        //         justify-content: space-between;
        //         width: 100%;
        //         max-width: none;
        //         margin: 10px 0 80px;

        //         .powered-by {

        //         }
        //         .links {

        //         }
        //     }
        // }

    }
    
}

.main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .main-content {
        position: relative;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    
    &.affiliate-quarter {
        display: block;
        .section {
    
            &.banner {
                padding: 20px 0;
                background: url(../img/main-banner.jpg) no-repeat top center;
                background-size: cover;
        
                @media screen and (max-width:710px) {
                    margin: 65px 0 0;
                }
            
                .top-nav {
                    @media screen and (max-width:710px) {
                        margin: -85px 0 0;
                    }
                    .page-logo {
                        a {
                            background-image: url(../img/aq-logo.svg);
                            @media screen and (max-width:710px) {
                                background-image: url(../img/aq-logo-black.png);
                            }
                        }
                    }
        
                    .nav {
                        li {
                            a {
                                //color: #8bc34a;
                                color: #fff;
                                @media screen and (max-width:710px) {
                                    color: #333;
                                }
                            }
                            &.btn.link {
                                a {
                                    color: #fff;
                                }
            
                            }
                        }
                    }
                }
            
                h1 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 60px;
                    margin: 400px 0 10px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #fff;
        
                    @media screen and (max-width:710px) {
                        margin: 200px 0 10px;
                        font-size: 31px;
                    }
                }
    
                .sub {
                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;
                    color: #fff;
                }
                .main-nav {
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    margin-bottom: 160px;
            
                    .btn {
                        margin: 0 5px;
                        a {
                            display: block;
                            font-size: 16px;
                            font-family: "Roboto Condensed";
                            padding: 10px 20px;
                            background-color: #fff;
                            border-radius: 5px;
                            text-transform: uppercase;
                            text-decoration: none;
                        }
                    }
                    @media screen and (max-width:710px) {
                        margin-bottom: 20px;
                    }
            
                }
            
            }
            h2 {
                font-family: "Zilla Slab";
                font-size: 40px;
                text-transform: uppercase;
                text-align: center;
            }
            &.offer {
                .offer-list {
                    display: flex;
                    justify-content: space-between;
                    padding: 0;
        
                    @media screen and (max-width:710px) {
                        display: block;
                    
                    }
                    
                    li {
                        font-family: "Zilla Slab";
                        font-size: 24px;
                        line-height: 1.2em;
                        width: 25%;
                        text-align: center;
                        padding: 150px 0 0;
                        margin: 15px;
                        background-repeat: no-repeat;
                        background-position: top center;
        
        
                        @media screen and (max-width:710px) {
                            margin: 15px auto;
                            width: 80%;
                        }
        
                        &.reliable-partner {
                            background-image: url(../img/reliable-partner-2-150x150.png);
                        }
                        &.strongly-growing-company {
                            background-image: url(../img/strongly-growing-company-150x150.png);
                        }
                        &.global-audience {
                            background-image: url(../img/global-audience-150x150.png);
                        }
                        &.stable-payouts {
                            background-image: url(../img/stable-payments-150x150.png);
                        }
                        &.transparent-reports-and-marketing-tools {
                            background-image: url(../img/marketing-reports-150x150.png);
                        }
                        &.high-commission-rates {
                            background-image: url(../img/commission-rates-150x150.png);
                        }
                        &.many-desktop-and-mile-offers {
                            background-image: url(../img/desktop-mobile-150x150.png);
                        }
                        &.dedicated-account-management {
                            background-image: url(../img/account-management-150x150.png);
                        }
                    }
                }
            }
    
            &.payout {
                border-top: 1px solid #e2e2e2;
                border-bottom: 1px solid #e2e2e2;
                background-color: #f4f4f4;
                .banner-list {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    @media screen and (max-width:710px) {
                        display: block;
                        padding: 20px;
                    }
        
                    .item {
                        width: 40%;
                        margin: 0 0 20px;
                        @media screen and (max-width:710px) {
                            width: 100%;
                        }
                        a {
                            display: block;
        
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
                .btn.link {
                    &.green {
                        max-width: 400px;
                        margin: 2em auto;
                    }
                }
            }
    
            &.brand {
                .logo {
                    width: 200px;
                    margin: 0 auto;
                }
            }
        
            &.contact {
                border-top: 1px solid #e2e2e2;
                border-bottom: 1px solid #e2e2e2;
                background-color: #f4f4f4;
                .note {
                    text-align: center;
                    @media screen and (max-width: 710px) {
                       margin: 20px;
                    }
                }
                .contact-form {
                    display: flex;
                    justify-content: center;
        
                    @media screen and (max-width: 710px) {
                        display: block;
                    }
        
                    fieldset {
                        width: 50%;
                        padding: 0 10px;
                        @media screen and (max-width: 710px) {
                           width: 100%;
                        }
                        p {
                            input {
                                // box-sizing: border-box;
                                // font-family: 'Open Sans', sans-serif;
                                // font-weight: 400;
                                // font-size: 16px;
                                // width: 100%;
                                //height: 50px;
                                // padding: 0 1em;
        
                                // border: 1px solid#e2e2e2;
                                // border-radius: 5px;
                                // outline: 0;
                                // transition: all .2s ease;
                            }
                            // textarea {
                            //     box-sizing: border-box;
                            //     font-family: 'Open Sans', sans-serif;
                            //     font-weight: 400;
                            //     font-size: 16px;
                            //     width: 100%;
                            //     height: 100%;
                            //     padding: 1em;
                            //     border: 1px solid#e2e2e2;
                            //     border-radius: 5px;
                            //     outline: 0;
                            //     transition: all .2s ease;
                            // }
                        }
        
                        &.submit {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: stretch;
        
                            .message {
                                flex: 1;
                            }
                            .btn.green {
                                input,
                                button {
                                    justify-content: center;
                                    width: 100%;
                                    padding: 17px 40px;
                                    text-align: center;
                                    color: #fff;
                                    background-color: #8bc34a;
                                }
                            }
                        }
                    }
                }
            }
    
            // &.footer {
            //     display: flex;
            //     justify-content: space-between;
            //     align-items: center;
            //     padding: 20px 10px 20px;
            //     background-color: #1d242d;
            //     @media screen and (max-width: 710px) {
            //         display: block;
            //      }
        
            //     .copyright {
            //         p {
            //             font-size: 13px;
            //             color: #bababa;
            //             margin: 0;
        
            //             a {
            //                 color: #fff;
            //             }
            //             @media screen and (max-width: 710px) {
            //                 text-align: center;
            //                 line-height: 2em;
            //              }
            //         }
        
            //     }
            //     .social {
            //         display: flex;
            //         justify-content: space-between;
            //         align-items: center;
            //         @media screen and (max-width: 710px) {
            //             justify-content: center;
            //          }
            //         p {
            //             width: 16px;
            //             height: 16px;
            //             margin: 5px;
            //             a {
            //                 display: block;
            //                 width: 100%;
            //                 height: 100%;
        
            //                 span {
            //                     display: flex;            
            //                     justify-content: center;
            //                     align-items: center;
            //                     width: 100%;
            //                     height: 100%;
            //                     svg {
            //                         color: #fff;
            //                     }
            //                 }
            //             }
            //         }
            //     }
            //     .links {
            //         margin: 0 0 0 auto;
            //     }
        
            // }
        }
    }
    &.dashboard {
        .content {
            background-color: #f0f0f0;
            h3 {
                font-size: 24px;
            }



        }
    }
    &.account {
        .content {
            background-color: #f0f0f0;
        }
    }
    &.campaigns {
        .content {
            background-color: #f0f0f0; 
        }
    }
    &.support {
        .content {
            background-color: #f0f0f0; 
        }
    }
}

// .btn.link {
//     display: block;
//     max-width: 300px;
//     margin: 60px auto 0;
//     text-align: center;
//     a {
//         display: block;
//         font-family: "Open Sans";
//         font-size: 18px;
//         font-weight: 600;
//         padding: 17px 40px;
//         text-transform: uppercase;
//         text-decoration: none;
//         border-radius: 5px;
//         color: #fff;
//         background-color: #8bc34a;

//     }
// }
.card {
    //width: 275px;
    width: 100%;
    .card-header {
        h3 {
                                
        }
        
    }
    .card-content {
        .value {
            font-size: 40px;
            line-height: 1.2em;
            margin: 15px 0;
            text-align: left;
            .number {
                display: block;
            }
            small {
                font-size: 24px;
            }
        }
        .go-to-link {
            font-size: 24px;
        }
    }

    &.total-click {
        color: #fff;
        background-color: #5E84EB;
    }
    &.new-user {
        color: #fff;
        background-color: #F254A1;
    }
    &.sale {
        color: #fff;
        background-color: #FBDB21;
    }

    &.op {
        padding: 0 20px;
        .op-list {
            margin: 0;
            li {
                border-bottom: 1px solid #d8d8d8;
                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 0;
                    text-decoration: none;
                    color: #000;
                }
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

    }
}
.summary {
    .card {
        width: 49%;
        min-width: 310px;
        margin: 0 1% 10px 0;
        .card-header {
            h3 {
                                    
            }
            
        }
        .card-content {
            
            .value {
                text-align: center;
                margin: 30px 0;
            }
            .go-to-link {
                text-align: right;
                a {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    text-decoration: none;
                    color: #7EC34D;

                    span {
                        line-height: 1.2em;
                    }
                    .ico {
                        margin: 0 0 0 10px;
                    }
                }
                > span {
                    color: #7EC34D;

                    &.date {
                        display: block;
                        font-size: 14px;
                        color: #000;
                    }
                }
            }
        }

        @media screen and (max-width: 960px) {
                width: 100%;
        }
        
    }
}

.stats {
    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .card {
        width: 32%;
        min-width: 215px;
        margin: 0 1% 10px 0;
        .card-content {
            display: flex;
            justify-content: space-between;
            .value {
                margin: 0;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
        @media screen and (max-width: 800px){
            width: 100%;
        }
    }
}

.date-select,
.campaign-select {
    position: relative;
    .selected-date,
    .selected-compaign {
        font-size: 16px;
        margin: 0;
    }
    .date-pick,
    .campaign-pick {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 140px;
        height: 0;
        margin-top: 10px;
        border-radius: 5px;
        background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        transition: height 0.1s;
        ul {
            margin: 0;

            li {
                a {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 1.2em;
                    padding: 10px;
                    text-decoration: none;
                    //border-bottom: 1px solid #dbdbdb;
                    color: #000;

                    img {
                        width: 20px;
                        height: 20px;
                        margin: 0 5px 0 0;
                    }
                }
            }
        }
        &.on {
            display: block;
            height: auto;
        }
    }
    .campaign-pick {
        width: 300px;
    }
}

.my-compaign {
    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
        .add-compaign {
            font-size: 16px;
            margin: 0;
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: #000;

                img {
                    margin: 0 5px 0 0;
                }
            }
        }
    }
    > div {
        width: 100%;
        flex-grow: 0;
        overflow-x: auto;
    }
    // .compaign-table {
    //     width: 100%;
    //     border-spacing: 0;
    //     table-layout: fixed;
    //     thead {
    //         tr {
                
    //             th {
    //                 font-size: 22px;
    //                 padding: 18px;
    //                 margin: 0;
    //                 border-top: 1px solid #dbdbdb;
    //                 border-left: 1px solid #dbdbdb;
    //                 border-radius: 0 0 0 0;
    //                 background-color: #f4f4f4;
    //                 &:first-of-type {
    //                     visibility: hidden;
    //                     border: none;

    //                     @media screen and (max-width: 960px) {
    //                         display: none;
    //                     }
    //                 }
    //                 &:nth-child(2) {
    //                     border-radius: 10px 0 0 0;
                        
    //                 }
    //                 &:nth-last-child(2) {
    //                     @media screen and (max-width: 960px) {
    //                         border-radius: 0;
    //                         border-bottom: 1px solid #dbdbdb;
                        
    //                     }
    //                 }
    //                 &:last-of-type {
    //                     width: 60px;
    //                     border-right: 1px solid #dbdbdb;
    //                     border-radius: 0 10px 0 0;
    //                     @media screen and (max-width: 960px) {
    //                         // border-radius: 0;
    //                         // border-bottom: 1px solid #dbdbdb;
    //                         display: none;
    //                     }
    //                 }
    //                 @media screen and (max-width: 960px) {
    //                     display: block;
    //                     font-size: 20px;
    //                     width: 100%;
    //                     border-bottom: 1px solid #dbdbdb;
    //                 }
    //                 @media screen and (max-width: 960px) {
    //                     width: 100%;
    //                     border-bottom: none;
    //                 }
    //             }
    //             @media screen and (max-width: 960px) {
    //                 display: block;
    //                 width: 100%;
    //             }
    //         }
    //         &.invisible {
    //             visibility: hidden;
    //             th {
    //                 padding: 0 18px;
    //             }

    //             @media screen and (max-width: 960px) {
    //                 visibility: visible;
    //                 th {
    //                     padding: 18px;
    //                 }
    //             }
    //         }
    //         @media screen and (max-width: 960px) {
    //             width: 50%;
    //         }                        
    //     }
    //     tbody {
    //         tr {
                
    //             td {
    //                 font-size: 20px;
    //                 padding: 18px;
    //                 text-align: center;
    //                 border: 1px solid #dbdbdb;
    //                 border: 0;
    //                 border-top: 1px solid #dbdbdb;
    //                 border-left: 1px solid #dbdbdb;
    //                 border-bottom: 1px solid #dbdbdb;
    //                 background-color: #fff;
    //                 &:first-of-type {
    //                     border-radius: 10px 0 0 10px;
    //                     @media screen and (max-width: 960px) {
    //                         border-radius: 10px 0 0 0;
    //                     }
    //                 }
    //                 &:last-of-type(2) {
    //                     border-right: 1px solid #dbdbdb;
    //                     @media screen and (max-width: 960px) {
    //                         border-left: 0;
    //                         border-bottom: 1px solid #dbdbdb;
    //                     }
    //                 }
    //                 &:last-of-type {
    //                     border-right: 1px solid #dbdbdb;
    //                     @media screen and (max-width: 960px) {
    //                         // border-left: 0;
    //                         // border-bottom: 1px solid #dbdbdb;
    //                         display: none;
    //                     }
    //                 }

    //                 @media screen and (max-width: 960px) {
    //                     display: block;
    //                     border-bottom: 0;
    //                     border-right: 1px solid #dbdbdb;
    //                 }
    //                 @media screen and (max-width: 960px) {
    //                     width: 100%;
    //                 }
    //             }
    //             @media screen and (max-width: 960px) {
    //                 display: block;
    //                 width: 100%;
    //             }
    //         }
    //         @media screen and (max-width: 960px) {
    //             width: 50%;
    //         }
    //     }

    //     @media screen and (max-width: 960px) {
    //         display: flex;
    //         justify-content: flex-start;
    //         align-items: flex-end;
    //         margin: 0 0 20px 0;
    //     }
    // }


}
.compaign-table {
    width: 100%;
    min-width: 800px;
    border-spacing: 0;
    table-layout: fixed;
    thead {
        tr {
            
            th {
                font-size: 22px;
                padding: 18px;
                margin: 0;
                border-top: 1px solid #dbdbdb;
                border-left: 1px solid #dbdbdb;
                border-radius: 0 0 0 0;
                background-color: #f4f4f4;
                &:first-of-type {
                    visibility: hidden;
                    border: none;
                }
                &:nth-child(2) {
                    border-radius: 10px 0 0 0;
                    
                }
                &:nth-last-child(2) {
                    
                }
                &:last-of-type {
                    width: 60px;
                    border-right: 1px solid #dbdbdb;
                    border-radius: 0 10px 0 0; 
                }
             
            }
        }
        &.invisible {
            visibility: hidden;
            th {
                padding: 0 18px;
            }
        }                      
    }
    &.total-earning {
        thead {
            tr {
                th {
                    &:last-of-type {
                        width: auto;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            
            td {
                font-size: 20px;
                padding: 18px;
                text-align: center;
                border: 1px solid #dbdbdb;
                border: 0;
                border-top: 1px solid #dbdbdb;
                border-left: 1px solid #dbdbdb;
                border-bottom: 1px solid #dbdbdb;
                background-color: #fff;
                &:first-of-type {
                    //border-radius: 10px 0 0 10px;
                }
                &:last-of-type(2) {
                    border-right: 1px solid #dbdbdb;
                }
                &:last-of-type {
                    border-right: 1px solid #dbdbdb;
                }
            }

            &:first-of-type {
                td {
                    &:first-of-type {
                        border-radius: 10px 0 0 0;
                    }
                }
            }
        }
    }
}
div.table {
    width: 100%;
    margin-bottom: 20px;
    .row-header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        padding: 18px;
        border-top: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-radius: 10px 10px 0 0;
        background-color: #fff;
    }
    .th-td {
        display: flex;

        > div {
            width: 50%;
            .th, .td {
                font-size: 20px;
                padding: 18px;
                text-align: center;
                border-top: 1px solid #dbdbdb;
                border-left: 1px solid #dbdbdb;
                &:last-of-type {
                    border-bottom: 1px solid #dbdbdb;
                }
            }
            .th {
                font-weight: bold;
            }
            .td {
                background-color: #fff;
                border-right: 1px solid #dbdbdb;
            }
        }
    }
}
.table-container {
    width: 100%;
    overflow-x: auto;
}
.compaign-detail-table {
    width: 100%;
    min-width: 400px;
    border-spacing: 0;
    thead {
        tr {
            th {
                font-size: 18px;
                padding: 18px;
                margin: 0;
                border-top: 1px solid #dbdbdb;
                border-left: 1px solid #dbdbdb;
                border-radius: 0 0 0 0;
                background-color: #e3e3e3;
                &:first-of-type {
                    border-radius: 10px 0 0 0;
                }
                &:last-of-type {
                    border-radius: 0 10px 0 0;
                    border-right: 1px solid #dbdbdb;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                font-size: 18px;
                padding: 18px;
                text-align: center;
                border: 1px solid #dbdbdb;
                border: 0;
                border-top: 1px solid #dbdbdb;
                border-left: 1px solid #dbdbdb;
                border-bottom: 1px solid #dbdbdb;
                background-color: #fff;
                
                &:last-of-type {
                    border-right: 1px solid #dbdbdb;
                }
            }
            &:nth-child(even) {
                td {
                    background-color: #f4f4f4;
                }
            }
        }
    }
}

.btn {
    text-align: center;
    border-radius: 5px;
    button {
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        padding: 10px 20px;
        cursor: pointer;
    }
    &.link {
        display: block;
        // max-width: 300px;
        margin: 0 auto 0;
        a {
            display: block;
            font-family: "Open Sans";
            font-size: 18px;
            font-weight: 600;
                //padding: 17px 40px;
            text-transform: capitalize;
            text-decoration: none;
            color: #333;
        }

    }
    &.green {

        a {
            display: inline-block;
            font-size: 14px;
            padding: 17px 40px;
            color: #fff;
            text-decoration: none;
            text-transform: capitalize;
            background-color: #8bc34a;
            
        }
        input,
        button {
            display: flex;
            align-items: center;
            margin: 0 auto;

            font-size: 14px;
            //padding: 17px 40px;
            padding: 10px 40px;
            border: none;
            color: #fff;
            background-color: #8bc34a;
        }
    }
    &.grey {
        a {
            display: inline-block;
            font-size: 14px;
            padding: 17px 40px;
            color: #fff;
            text-decoration: none;
            text-transform: capitalize;
            background-color: #333;
            
        }
        input,
        button {
            display: flex;
            align-items: center;
            margin: 0 auto;

            font-size: 14px;
            //padding: 17px 40px;
            padding: 10px 40px;
            border: none;
            border-radius: 5px;
            color: #fff;
            background-color: #333;
        }
    }
    &.round {
        a,
        input,
        button {
            border-radius: 20px;
        }
    }
}

.tab {
    .tab-header {
        ul {
            display: flex;
            justify-content: space-between;
            li {
                font-size: 14px;
                width: 100%;
                padding: 10px 0;
                cursor: pointer;
                &.on {
                    border-bottom: 2px solid #8bc34a;
                }
            }
        }
    }
    .tab-content {
        position: relative;
        height: 120px;
        .content {
            display: none;
            //position: absolute;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 1em 0;
            background-color: #fff;
            z-index: 0;

            &.on {
                display: block;
                z-index: 1;
            }
        }
    }
}

.resource {
    .tab {
        .tab-header {
            background-color: #e3e3e3;
            ul {
                li {
                    text-align: center;
                    &.on {
                        color: #fff;
                        background-color: #333333;
                        border: none;
                    }
                }
            }

        }
        .tab-content {
            height: auto;
            .content {
                height: auto;
                padding: 1%;

                .banner {
                    max-width: 100%;
                    img {
                        // width: auto;
                        // height : auto;
                        max-height: 400px;
                        max-width: 100%;
                    }
                }
            }

        }
    }
}

.react-responsive-modal-modal {
    max-width: 940px;
    min-width: 350px;
    //max-height: 350px;
    &.verification-modal,
    &.country-code-modal {
        text-align: center;
        h2 {
            font-family: "Mulish";
            margin: 10px 0;
        }
        p {
            //margin: 0.5em 0;
            &.note {
                margin: 1em 0;
            }
        }
    }
    &.verification-modal {
        .verification-form {
            text-align: left;
            .input {
                margin: 1em 0;
                label {
                    display: block;
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }
        }
    }
    &.country-code-modal {
        height: 100%;
        .country-code {
            height: calc(100% - 50px);
            overflow-y: auto;
            li {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-bottom: 1px solid #e2e2e2;
                cursor: pointer;
                &:hover {
                    background-color: #f4f4f4;
                }
                &:last-of-type {
                    border: none;
                }
            }
            
        }
    }

    .react-responsive-modal-closeButton {
        z-index: 2;
    }

    .btn {
        &.link {
            a {
                padding: 10px 40px;
            }
    
        }
        &.green {
            a {
                padding: 10px 40px;
            }
            input {
                padding: 10px 40px;
            }
    
        }
    }
}
.confirm-message-modal {
    .heading {
        text-align: center;
    }
    .sub-heading {
        text-align: center;
    }
    .msg {
        text-align: left;
        margin: 1em 0;
    }
}
.contact-support-modal {
    padding: 0;
    width: 420px;
    height: 620px;
    .contact-support {
        height: auto;
        background-color: #fff;
    }
}
.ticket-list-modal {
    padding: 0;
    width: 420px;
    height: 620px;
    .ticket-list {
        background-color: #fff;
    }
}
.ticket-detail-modal {
    padding: 0;
    width: 420px;
    height: 620px;
    .ticket-detail {
        position: relative;
        height: 600px;
        background-color: #fff;

        .header {
            position: absolute;
            z-index: 1;
            background-color: #fff;
        }
        .ticket-msg-container {
            height: 100%;
            overflow-y: auto;
        }
        .msg-input {
            position: absolute;
        }

    }
}
.ticket-message-modal {
    padding: 0;
    width: 420px;
    height: 620px;
    .ticket-msg {
        height: 600px;
        background-color: #fff;
    }
}

.img-full-view-modal {
    .react-responsive-modal-closeButton {
        padding: 2px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
    }
    .attachment-image {
        width: 100%;
        img {
            max-width: 100%;
        }
    }
}

.tc-modal {
    width: 100%;
    height: 80vh;
    padding: 0;
    overflow: hidden;
}

.reg {
    margin: 0 0 auto;
    .content {
        display: flex;
        width: 100%;
        margin: 2em auto 0;

        h2 {
            font-family: "Mulish";
            font-weight: bold;
            font-size: 26px;
            margin: 0 0 1em;
            text-transform: capitalize;
        }
        fieldset {
            h3 {
                margin: 0;
            }
        }
        .application-info {
            width: 50%;
            padding: 2em;

            @media screen and (max-width: 780px) {
                display: none;
            }

            ol {
                padding: 0;

                li {
                    list-style: none;

                    h3 {
                        font-size: 24px;
                        padding: 1.1em 0 1.1em 100px;
                        background: url("../img/no2-ico.svg") no-repeat left 50%;
                    }

                    &:first-of-type {
                        h3 {
                            background-image: url("../img/no1-ico.svg");
                        }
                    }

                    &:last-of-type {
                        h3 {
                            background-image: url("../img/no3-ico.svg");
                        }
                    }
                }
            }
        }
    }
}

.reg-form,
.acc-form {
    
    @media screen and (max-width: 640px) {
        width: 100%;
    }

    fieldset {
        &.input {
            > p {
                width: 100%;
            }
            input[type=text],
            input[type=email],
            input[type=number],
            input[type=password],
            input[type=tel] {
                font-family: 'Mulish';
                font-size: 14px;
                height: 40px;
                outline: 0;
            }
            input[type=checkbox] {
                margin: 0 10px 0 0;
            }
            .select {
                select {
                    font-size: 14px;
                    height: 38px;
                }
            }
            .phone {
                display: flex;
                flex-wrap: wrap;
                label {
                    width: 100%;
                }
                .field {
                    display: flex;
                    width: 100%;
                    .area-code {
                        flex-grow: 0;
                        width: 50px;
                        padding: 0 8px;
                        margin: 0 10px 0 0;
                    }
                    .phone-number {
                        flex-grow: 1;
                        width: auto;
                        padding: 0 8px
                    }
                }

            }

            .bank-transfor {
                .bank-detail {
                    span.input {
                        margin: 1em 0;
                    }
                }

            }


            .checkbox {
                display: block;
            }
            .input {
                display: block;
            }
        }
        &.submit {
            display: flex;
            justify-content: space-between;
            margin-top: 1em;
            .btn.link {
                margin: 0;
                a {
                    font-size: 14px;
                    padding: 10px 20px;
                }

                &.green {
                    margin: 0 0 0 auto;
                }
            }
        }
    }


}

.reg-form {
    width: 50%;
    min-width: 450px;
    padding: 2em;
    background-color: #efefef;
    @media screen and (max-width: 780px) {
        width: 100%;
        min-width: auto;
    }
    .user-details {
        .input {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media screen and (max-width: 640px) {
                flex-wrap: wrap;
            }
            > p {
                width: 49%;

                @media screen and (max-width: 640px) {
                    width: 100%;
                }
            }

            &:first-of-type {
                > p {
                    width: 100%;
                }
            }
        }
    }
}

.contact-form {
    .submit {
        display: flex;
        justify-content: space-between;
        margin-top: 1em;
    }
}

.acc-form {
    .user-details {
        fieldset {
            width: 100%;
            p {
                display: flex;
                align-items: center;
                > span {
                    display: block;
                    width: 60%;
                    @media screen and (max-width: 780px) {
                        width: 100%;
                    }
                }
                .update {
                    width: 30%;
                    margin: 25px 0 0 1%;
                    @media screen and (max-width: 780px) {
                        width: 100%;
                        margin: 0;
                    }
                }
                @media screen and (max-width: 780px) {
                    flex-wrap: wrap;
                }
            }
        }
    }
    fieldset {
        width: 60%;
        @media screen and (max-width: 780px) {
            width: 100%;
        }
        &.submit {
            width: 100%;
        }
    }
}

.accordion {
    margin: 0 0 20px;
    .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        h2 {
            margin: 0;
        }
    }
    .accordion-content {
        height: 0px;
        overflow: hidden;
        transition: height ease 0.5s;
    }
    &.open {
        .accordion-content {
            height: auto;
            margin: 2em 0 0;
        }
    }

}

.generated-link {
    .copy-link {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            margin: 0 0 0 10px;
        }
    }
}

.account-names {
    ul {
        li {
            line-height: 1.2em;
            margin: 0;
            border-bottom: 1px solid #e3e3e3;

            a {
                display: block;
                padding: 20px;
                color: #333;
                text-decoration: none;
                &:hover {
                    background-color: #e3e3e3;
                }
            }
            &:last-of-type {
                    border: none;
            }
        }
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 10px 20px;
    background-color: #333;
    @media screen and (max-width: 710px) {
        display: block;
     }

    .copyright {
        p {
            font-size: 13px;
            color: #bababa;
            margin: 0;

            a {
                color: #fff;
            }
            @media screen and (max-width: 710px) {
                text-align: center;
                line-height: 2em;
             }
        }

    }
    .social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 710px) {
            justify-content: center;
         }
        p {
            width: 16px;
            height: 16px;
            margin: 5px;
            a {
                display: block;
                width: 100%;
                height: 100%;

                span {
                    display: flex;            
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    svg {
                        color: #fff;
                    }
                }
            }
        }
    }

    .links {
        display: flex;
        margin: 0 0 0 auto;
        justify-content: center;

        li {
            padding: 5px 10px;

            border-right: 1px solid #fff;
            
            a {
                color: #fff;
            }

            &:last-of-type {
                border: none;
            }
        }
    }

}

.empty-state {
    max-width: 400px;
    margin: 5em auto 2em;
    text-align: center;

    .empty-msg {
        font-size: 22px;
    }
    .btn {
        a {
            padding: 10px 40px;
        }
        
    }
}
.empty {
    &.folder {
        text-align: center;
        font-size: 120px;
        svg {
            color: #ccc;
        }
    }
}

.af-prgm {
    p {
        display: inline-block;
        line-height: 1.2em;
        padding: 10px;
        margin: 0 0 1em;
        border-radius: 5px;
        background-color: #f4f4f4;
        vertical-align: middle;
    }
}

.contact-support,
.ticket-list,
.ticket-detail,
.ticket-msg {
    height: 100%;
    min-height: 100%;
    //background-color: #f0f0f0;
    
    .header {
        //position: relative;
        //position: fixed;
        position: sticky;
        top: 0;
        width: 100%;
        //background-color: #f0f0f0;
        background-color: #fff;

        .page-header {
            font-size: 16px;
            line-height: 1.2em;
            width: 100%;
            padding: 15px;
            margin: 0;
            text-align: center;
        }
        .back-btn {
            position: absolute;
            top: 0;
            left: 0;

            box-sizing: border-box;
            width: 50px;
            padding: 15px 20px;
            margin: 0;
        }
        .btn {
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px 15px;
            border-radius: 15px;
            overflow: hidden;

            button {
                //padding: 10px 20px;
                padding: 6px 15px;
            }
        }
    }
    .contact-form {
        label {
            padding: 0 1em;
        }
        input {
            border-radius: 0;
        }
    }
    .confirm-msg {
        .content {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .ico {
                font-size: 60px;

                &.success-ico {
                    color: #8bc34a;
                }
                &.fail-ico {
                    color: #E5183F;
                }
            }

        }
    }
}
.file-uploader {
    [type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label {
        display: flex;
        align-items: center;
    }
    .upload-btn {
        position: relative;
        font-size: 14px;
        padding: 10px 30px;
        margin: 0 0 0 10px;
        border-radius: 5px;
        background-color: #ccc;
        cursor: pointer;

        .ico {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 20px;
            height: 20px;
        }
    }
    .preview {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .thumbnail {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 10px;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: #ccc;
            border-radius: 10px;

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-size: 12px;
            }
            .ico {
                position: absolute;
                top: auto;
                left: auto;
                bottom: 0;
                right: 0;
                width: 20px;
                height: 20px;
                transform: none;
                cursor: pointer;
                img {
                    display: block;
                }
            }
            img {
                display: none;
            }
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
}
.ticket-list {
    .ticket {
        //padding: 50px 0 0;
        padding: 0;
        margin: 0;
        
        .empty {
            width: 100%;
            text-align: center;
        }

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 20px;
            margin: 0;
            border-bottom: 1px solid #d8d8d8;
            &:hover {
                background-color: #f4f4f4;
            }

            &:last-of-type {
                border: none;
            }

            .date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                text-transform: uppercase;
                margin: 0 10px 0 0;
                .day {
                    font-weight: bold;
                    font-size: 20px;
                }
                .month {

                }

            }
            .details {
                flex-grow: 1;
                max-width: 300px;
                .title {
                    
                }
            }
            .status {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 0 0 10px;
                .new-msg {
                    font-size: 12px;
                    padding: 0 4px;
                    margin: 0 0 3px;
                    border-radius: 50px;
                    background-color: black;
                    color: #fff;
                }
                .btn {
                    font-size: 12px;
                    padding: 2px 5px;
                    border-radius: 5px;
                    background-color: #ccc;
                }
            }
        }
    }
}

.ticket-detail,
.ticket-msg {
    .header {
        background-color: #f0f0f0;
        .page-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 50px;
            padding: 0;
    
            .title {
                font-size: 16px;
            }
            .ticket-id {
                font-size: 10px;
                line-height: 1em;
                color: #666;
            }
        }
    }
}
.ticket-detail {
    height: 100%;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    .ticket-msg-list {
        padding: 50px 15px;

        .msg-date {
            font-size: 12px;
            text-align: center;
            padding: 10px 10px 6px;
            color: #666;
        }

        .msg {
            display: flex;
    
            .msg-content {
                display: flex;
                flex-direction: column;
                max-width: 80%;
    
                .time {
                    font-size: 9px;
                    margin-top: 7px;
                    margin-bottom: 3px;
                    color: #535353;
                }
                .text {
                    padding: 10px;
                    border-radius: 10px;
                    background-color: #fff;
                    color: #535353;
                    text-align: left;
                    -ms-flex-item-align: start;
                    align-self: flex-start;
                    white-space: pre-line;
                    word-break: break-word;
    
    
                    p {
                        margin: 0;
                    }
                }
                .attachment {
                    margin-top: 5px;
                    border-radius: 4px;
                    text-align: left;

                    img {
                        width: 70px;
                        -o-object-fit: cover;
                        object-fit: cover;
                        height: 150px;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
            .user-avatar {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                margin-right: 9px;
                background-color: #000;
    
                img {
                    width: 100%;
                    border-radius: 50%;
                }
                .fa-user {
                    font-size: 18px;
                    color: #fff;
                }
    
                .online-status {
                    position: absolute;
                    bottom: -2px;
                    right: -2px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    background-color: #7bcb45;
                }
            }
    
            &.my-msg {
                justify-content: flex-end;
                .time {
                    text-align: right;
                }
                .text {
                    background-color: #7bcb45;
                    color: #fff;
                    white-space: pre-line;
                    word-break: break-word;
                }

                .attachment {
                    text-align: right;
                }
            }
        }
    }
    .msg-input {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        display: flex;
        align-items: center;
        background-color: #f4f4f4;

        .photo-ico {
            padding: 10px;
            cursor: pointer;
        }
        .input-toolbar {
            flex: 1 1;
            height: 35px;
            padding: 0 10px;
        }
        .send-ico {
            padding: 10px;
        }
    }
}

.ticket-msg {
    .msg {
        &.input {
            padding: 0;
            p {
                //height: 250px;
                margin: 0;
                textarea {
                    min-height: 250px;
                }
            }
        }
    }
    .file-uploader {
        padding: 10px; 
    }
    .submit {
        p {
            margin: 0.5em 0 0;
            button {
                border-radius: 5px;
            }
        }
    }
}


.tc {
    height: 100%;
    overflow-y: auto;

    header.tool-bar {
        position: sticky;
        top: 0;
        left: 0;
        right: auto;
        margin: 0;
        background-color: #fff;

        h1 {
            font-family: "Muli", Helvetica, Arial, sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 42px;
            padding: 0;
            margin: 0;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.85);
            overflow: hidden;
        }
    }

    .content {
        width: 100%;
        margin: 0;

        ul {
            margin: 0;
            li {
                margin: 10px 10px 10px 18px;

                h3 {
                    margin: 10px 0 8px ;
                }
                h4 {
                    margin: 10px 0 8px;
                }
                p {
                    margin: 10px 0;
                }
            }
        }
    }

}